
import { Vue, Component, Watch } from "vue-property-decorator";
import Mustache from "mustache";
import { DateTime } from "luxon";

type Configuration = {
  version: string;
  ssid: string;
  password: string;
  timezone: string;
  apkInstall: boolean;
  kiosk: boolean;
  wlan: boolean;
  bluetooth: boolean;
  rfid: boolean;
  deviceName: string;
  hostName: string;
  httpPort: number;
  adminUser: string;
  adminPassword: string;
  autoReboot: boolean;
  developer: boolean;
};

@Component({
  metaInfo: {
    title: "Display Configurator",
    titleTemplate: "%s | Flexopus",
  },
})
export default class App extends Vue {
  validForm = true;
  showPassword = false;
  options = {
    version: ["v1.10.21"],
    apkVersion: ['1.3.0'],
    get timezone(): string[] {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const tzData = require("tzdata");
      return Object.keys(tzData.zones);
    },
  };
  configuration: Configuration = {
    version: "v1.10.21",
    ssid: "",
    password: "",
    timezone: DateTime.local().zoneName,
    apkInstall: true,
    kiosk: true,
    wlan: true,
    bluetooth: false,
    rfid: false,
    deviceName: "TAB10s",
    hostName: "TAB10s",
    httpPort: 80,
    adminUser: "admin",
    adminPassword: "admin",
    autoReboot: true,
    developer: false,
  };
  history = [] as {
    configuration: Configuration;
    rendered: string;
    createdAt: DateTime;
    url: string;
  }[];

  now = DateTime.now().setLocale("en");
  nowInterval = null as number | null;

  mounted(): void {
    if (this.nowInterval) {
      window.clearInterval(this.nowInterval);
    }
    this.nowInterval = window.setInterval(this.updateNow, 1000);
  }

  beforeDestroy(): void {
    if (this.nowInterval) {
      window.clearInterval(this.nowInterval);
    }
  }

  @Watch("configuration.timezone")
  updateNow(): void {
    this.now = DateTime.now()
      .setZone(this.configuration.timezone)
      .setLocale("en");
  }

  generate(): void {
    const createdAt = DateTime.now();
    const template =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require(`raw-loader!@/assets/configuration-templates/configuration_${this.configuration.version}.js.template`).default;
    const rendered = Mustache.render(template, {
      ...this.configuration,
      createdAt,
    });
    //this.download("configuration.js", rendered);
    const blob = new Blob([rendered], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    this.history.push({
      configuration: { ...this.configuration },
      rendered,
      createdAt,
      url,
    });
    this.$nextTick(() => {
      window.scroll({ top: window.innerHeight * 100, behavior: "smooth" });
    });
  }

  versionAllows(config: keyof Configuration): boolean
  {
    const versionMap: Partial<{ [Property in keyof Configuration]: string }> = {
      autoReboot: 'v1.10.21'
    };

    return (versionMap[config] ?? this.options.version.slice(-1)[0]) <= this.configuration.version
  }
}
